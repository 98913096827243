var fn =
{
colours: ['crimson', 'firebrick', 'hotpink', 'coral', 'gold', 'indigo', 'mediumorchid', 'lightgreen', 'darkseagreen', 'aquamarine', 'steelblue', 'deepskyblue', 'tan', 'sandybrown'],
config: {
	type: 'line',
	data: { labels: [], datasets: [] },
	options: {
		responsive: true,
		maintainAspectRatio: false,
		tooltips: { mode: 'index', intersect: false },
		hover: { mode: 'nearest', intersect: true },
		scales: {
			xAxes: [{ display: true, scaleLabel: { display: true, labelString: 'Year' } }],
			yAxes: [{ ticks: { reverse: true }, display: true, scaleLabel: { display: true, labelString: 'Rank' } }]
		}
	}
},
dataStore: {},
jurisdictions: {},
maxYear: 0,
minYear: 9999,
parsedData: {},
years: [],

initialisePopularity: function()
{
	var canvas = document.getElementById( 'popularityChart' );
	if( canvas == null ) return false;
	fn.initialiseForm();
	fn.getYears( initialRanks );
	fn.parseData( initialRanks );
	datasets = fn.prepareDatasets();
	
	fn.config.data.labels = fn.years;
	fn.config.data.datasets = datasets;

	window.onload = function()
	{
		var ctx = canvas.getContext( '2d' );
		window.popularityChart = new Chart( ctx, fn.config );
	};
},

prepareDatasets: function()
{
	var colours = fn.colours.slice( 0 );
	var datasets = [];
	$.each( fn.parsedData, function( placeId, data )
	{
		var randomKey = Math.floor( Math.random() * ( colours.length - 1 ) );
		var randomColour = colours[randomKey];
		colours.splice( randomKey, 1 );;
		datasets.push( { label: fn.jurisdictions[placeId], backgroundColor: randomColour, borderColor: randomColour, data: data, fill: false } );
	});
	
	return datasets;
},

initialiseForm: function()
{
	$( '#popularity-form' ).on( 'keyup change paste', 'input', function()
	{
		var placeIds = [];
		var combined = {};
		var total = 0;
		var checked = $( '#popularity-form :checked' );
		$.each( checked, function( i, input )
		{
			if( total > 10 )
				input.checked = false;
			else if( fn.dataStore[input.value] == undefined )
				placeIds.push( input.value );
			else
				combined[input.value] = fn.dataStore[input.value];
			total++;
		});
		
		if( total >= 10 )
		{
			$.each( $( '#popularity-form input' ), function( i, input )
			{
				if( input.checked == false )
					input.disabled = true;
			});
		}
		else if( total == 9 )
		{
			$.each( $( '#popularity-form input' ), function( i, input )
			{
				if( input.disabled == true )
					input.disabled = false;
			});
		}
		else if( total == 0 )
			return false;
		
		if( placeIds.length > 0 )
		{
			var url = 'a?c=Name&m=getPopularity&nameId=' + sm.surnameId;
			$.ajax(
			{
				type: 'POST',
				url: url,
				data: { placeIds: placeIds },
				dataType: 'json',
				success: function( data )
				{
					$.extend( data, combined );
					fn.updateChart( data );
				}
			});
		}
		else
			fn.updateChart( combined );
	});
	
	$.each( $( '#popularity-form input' ), function( i, input )
	{
		fn.jurisdictions[input.value] = input.parentNode.innerText;
	});
},

updateChart: function( ranks )
{
	fn.getYears( ranks );
	fn.parseData( ranks );
	datasets = fn.prepareDatasets();
	
	fn.config.data.labels = fn.years;
	fn.config.data.datasets = datasets;
	
	window.popularityChart.update();
},

getYears: function( ranks )
{
	fn.maxYear = 0;
	fn.minYear = 9999;
	$.each( ranks, function( placeId, block )
	{
		var lastYear = null;
		$.each( block, function( i, data )
		{
			if( data.yob > fn.maxYear )
				fn.maxYear = data.yob;
			if( data.yob < fn.minYear )
				fn.minYear = data.yob;
		});
	});
	
	fn.years = [];
	for( var i = fn.minYear; i <= fn.maxYear; i++ )
		fn.years.push( i );
},

parseData: function( ranks )
{
	fn.parsedData = {};
	
	$.each( ranks, function( placeId, block )
	{
		fn.dataStore[placeId] = block;
		fn.parsedData[placeId] = [];
		var lastYear = fn.minYear - 1;
		$.each( block, function( i, data )
		{
			if( lastYear!= data.yob && ( lastYear != ( data.yob - 1 ) ) )
			{
				var difference = ( data.yob - lastYear ) - 1;
				for( difference; difference > 0; difference-- )
					fn.parsedData[placeId].push( null );
			}
			fn.parsedData[placeId].push( data.rank );
			lastYear = data.yob;
		});
	});
}
}

if( typeof jQuery == 'undefined' )
{
	document.addEventListener( 'DOMContentLoaded', fn.initialisePopularity, false );
}
else
	fn.initialisePopularity();