var doughnutChartOptions =
{
	maintainAspectRatio: false,
	tooltips: {
		enabled: false
	},
	legend: {
		display: false
	},
	onHover: function( e, c )
	{
		if( c.length )
		{
			var holder = $( c[0]._chart.canvas ).parent();
			var index = c[0]._index;
			holder.find( '.subtitle' ).html( c[0]._chart.config.data.datasets[0].data[index] );
			holder.find( '.title' ).html( c[0]._chart.config.data.labels[index] );
		}
	}
};

var prepareChart = function()
{
	$( '.chart' ).each(function()
	{
		$( this ).append( '<canvas></canvas>' );
	});
	
	if( $( '#chart1' ).length )
	{
		var values = [];
		var labels = [];
		var colours = [];
		var highest = 0;
		var title1 = '';
		var subtitle1 = 0;
		jQuery.each( stat, function( i, j )
		{
			labels.push( data[j.metricRef].name );
			values.push( parseFloat( j.value ) );
			colours.push( data[j.metricRef].colour );
			if( j.value > highest )
			{
				title1 = data[j.metricRef].name;
				subtitle1 = j.value;
				highest = j.value;
			}
		});
		
		var ctx = $( '#chart1 canvas').get( 0 ).getContext( '2d' );
		var myChart = new Chart( ctx, {
			type: 'pie',
			data: {
				labels: labels,
				datasets: [{
					data: values,
					backgroundColor: colours,
					borderWidth: 0
				}]
			},
			options: doughnutChartOptions
		});
		
		var values = [];
		var labels = [];
		var colours = [];
		var highest = 0;
		var title2 = '';
		var subtitle2 = 0;
		jQuery.each( data, function( i, j )
		{
			labels.push( j.name );
			values.push( parseFloat( j.value ) );
			colours.push( j.colour );
			if( j.value > highest )
			{
				title2 = j.name;
				subtitle2 = j.value;
				highest = j.value;
			}
		});
		
		var ctx = $( "#chart2 canvas" ).get( 0 ).getContext( '2d' );
		var myChart = new Chart(ctx, {
			type: 'pie',
			data: {
				labels: labels,
				datasets: [{
					data: values,
					backgroundColor: colours,
					borderWidth: 0
				}]
			},
			options: doughnutChartOptions
		});
	}
	
	$( '#chart1' ).append( '<div class="helper"><div class="content"><div class="title">' + title1 + '</div><div class="subtitle">' + subtitle1 + '%</div></div><div class="rotator"></div></div>' );
	$( '#chart2' ).append( '<div class="helper"><div class="content"><div class="title">' + title2 + '</div><div class="subtitle">' + subtitle2 + '%</div></div><div class="rotator"></div></div>' );
}

if( typeof jQuery == 'undefined' )
{
	document.addEventListener( 'DOMContentLoaded', prepareChart, false );
}
else
	prepareChart();